import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {allNotConfirmedOrigins, allOrigins} from "../ResultAction";

export const confirmOrigins = (data) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${data.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);

    const init = {
        method: 'PUT',
        headers: headers,
        body: `{"origins":${JSON.stringify(data.origins)}}`
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${data.email}/origins/confirm`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, data.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(responseData => {
                if (responseStatus === 200) {
                    dispatch(clientRequest(`successfully confirmed ${data.origins.length} ${data.origins.length === 1 ? 'origin' : 'origins'}`));
                    dispatch(allNotConfirmedOrigins(responseData));
                    // dispatch(allOrigins(responseData));
                }else{
                    dispatch(clientRequest(`origins not confirmed`));
                }
            })
            .catch(() => {
                authorizationResponseStatus(responseStatus, data.email, dispatch);
            });
    }
};