import React, {useEffect} from 'react';
import style from "../styles/Video.module.css";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getModeratedAccounts} from "../actions/setting/ModeratedAccountAction";
import {currentlyModeratedAccount} from "../actions/ResultAction";
import {getAllOrigins} from "../actions/setting/AllOriginsaction";

const ModeratedAccount = (props) => {

    useEffect(() => {
        if (props.moderated.length === 0) {
            props.getModeratedAccounts(props.user.email);
        }

        if (!props.currentlyModerated){
            props.currentlyModeratedAccount(props.user.email);
        }
    }, []);

    const handleSelect = (e) => {
        if (e.target) {
            props.currentlyModeratedAccount(e.target.value);
            props.getAllOrigins({
                email: props.user.email,
                moderatedAccount:  e.target.value
            });
        }
    };

    if (props.moderated) {
        return (
            <div className={style.topForm}>
                <label className={'d-flex mb-0'}>
                     moderated account :
                    <select value={props.currentlyModerated}
                            className={style.fieldList}
                            name="moderated" id="moderated"
                            onChange={handleSelect}>
                        {props.moderated.map((item, index) =>
                            <option className={style.black}
                                    value={`${item}`}
                                    key={index}>
                                {`${item}, role:${props.user.email === item ? 'admin' : 'moderator'}`}
                            </option>
                        )}
                    </select>
                </label>
            </div>
        );
    } else {
        return (
            <div>

            </div>
        )
    }
    };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllOrigins,
        getModeratedAccounts,
        currentlyModeratedAccount
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        currentlyModerated: state.result.currentlyModeratedAccount,
        moderated: state.result.moderatedAccounts
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModeratedAccount);