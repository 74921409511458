import {
    ALL_MODERATORS, ALL_NOT_CONFIRMED_ORIGINS,
    ALL_ORIGINS, CURRENTLY_MODERATED_ACCOUNT, FRAME_ID, MODERATED_ACCOUNTS,
    SAFETY_ADJUSTMENTS,
    SELECTED_VIDEO,
    TEMP_VIDEO_LIST,
    VIDEO_LIST
} from "../actions/ResultAction";

const init = {
   list: [],
    tempList: [],
    video: '',
    origins: [],
    safety: [],
    moderators: [],
    moderatedAccounts: [],
    currentlyModeratedAccount: "",
    frameID: '',
    notConfirmedOrigins: []
};

export default function (state = init, action) {
    switch (action.type) {
        case VIDEO_LIST:
            return {...state, list: action.payload};
        case TEMP_VIDEO_LIST:
            return {...state, tempList: action.payload};
        case SELECTED_VIDEO:
            return  {...state, video: action.payload};
        case ALL_ORIGINS:
            return  {...state, origins: action.payload};
        case ALL_NOT_CONFIRMED_ORIGINS:
            return  {...state, notConfirmedOrigins: action.payload};
        case SAFETY_ADJUSTMENTS:
            return  {...state, safety: action.payload};
        case ALL_MODERATORS:
            return  {...state, moderators: action.payload};
        case MODERATED_ACCOUNTS:
            return  {...state, moderatedAccounts: action.payload};
        case CURRENTLY_MODERATED_ACCOUNT:
            return  {...state, currentlyModeratedAccount: action.payload};
        case FRAME_ID:
            return  {...state, frameID: action.payload};
        default:
            return state;
    }
}