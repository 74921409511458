import React from 'react';
import {connect} from "react-redux";
import PluginCode from "./PluginCode";
import User from "./accountPages/User";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrustOrigin from "./TrustOrigin";
import style from "../styles/Chekbox.module.css";
import Collapse from "react-bootstrap/Collapse";

const MainPlugin = (props) => {

        return(
            <Container
                // className={props.notConfirmedOrigins.length === 0 ? 'd-flex align-items-center' : ''}
                className={'m-auto'}
            >

                <Row  className={`d-flex align-items-center justify-content-center ${style.confirm}`}>
                    <Collapse in={props.notConfirmedOrigins.length > 0}>
                    <Col xs={12}>
                        <TrustOrigin/>
                    </Col>
                    </Collapse>
                </Row>


                <Row  className={'d-flex align-items-center justify-content-center'}>
                    <Col xl={8} lg={12}>
                        <PluginCode/>
                    </Col>
                    <Col className={'d-flex align-items-center justify-content-center mt-5'} xl={4} lg={12}>
                        <User userClass={'user'}/>
                    </Col>
                </Row>

            </Container>
        );
    };

function mapStateToProps(state) {
    return {
        notConfirmedOrigins: state.result.notConfirmedOrigins,
    };
}

export default connect(mapStateToProps)(MainPlugin);
