import React from 'react';
import {connect} from "react-redux";
import {Nav, NavDropdown} from "react-bootstrap";
import {bindActionCreators} from "redux";
import Navbar from "react-bootstrap/Navbar";
import {isLogin} from "../actions/IsLoginAction";
import {switchActivePage, switchActivePageExpPassword, switchVideosActivePage} from "../actions/SwitchPageAction";
import {clientRequest} from "../actions/StandartRequestActions";
import {LinkContainer} from "react-router-bootstrap";
import {pagesName, pagesNameVideos, pagesNameSettings} from "../const/Constant";
import style from '../styles/Navigation.module.css';
import {allModerators, currentlyModeratedAccount} from "../actions/ResultAction";
import Logo from "./Logo";


function Navigation(props){

    const handleSelect = (eventKey) => props.switchPage(eventKey);

    const handleSelectPasswordExpired = (eventKey) => props.switchPagePasswordExpired(eventKey);

    const handleLogOut = () => {
        props.logIn(false);
        props.footerInfo('');
        props.currentlyModeratedAccount('');
        props.allModerators([]);
        localStorage.removeItem(`videocommentsexpireduser${props.user.email}`);
        localStorage.removeItem(`videocommentsxtokenuser${props.user.email}`);
        localStorage.removeItem(`videocommentsuser${props.user.email}`);
        window.location.reload();
    };

    if (!props.isPasswordExpired) {
        return (
            <>
                <Navbar collapseOnSelect
                         expand="xl"
                         bg="dark"
                         variant="light"
                >

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" label={"Menu"} />
                    <Navbar.Collapse id="responsive-navbar-nav">

                <Nav fill variant="tabs" className={style.menu} defaultActiveKey={`${props.activePageDefault.link}`} onSelect={handleSelect}>
                    <Nav.Item>
                        <LinkContainer to={'/main/home'}><Nav.Link eventKey="home" >Main</Nav.Link></LinkContainer>
                    </Nav.Item>
                    <NavDropdown title="Account" id="nav-dropdown">
                    {Object.keys(pagesName).map((item,index) =>
                        <LinkContainer to={`/main/${item}`}  key={index}>
                            <NavDropdown.Item eventKey={item}>
                                {pagesName[item] !== 'User roles' ? pagesName[item] : props.user !== undefined
                                && props.user.roles !== undefined && props.user.roles.includes("ROLE_ADMIN") ? pagesName[item]: ''}
                            </NavDropdown.Item>
                        </LinkContainer>
                     )}
                    </NavDropdown>
                    <NavDropdown title="Settings" id="nav-dropdown">
                        {Object.keys(pagesNameSettings).map((item,index) =>
                            <LinkContainer to={`/main/${item}`}  key={index}>
                                <NavDropdown.Item eventKey={item}>
                                    {pagesNameSettings[item]}
                                </NavDropdown.Item>
                            </LinkContainer>
                        )}
                    </NavDropdown>
                    <NavDropdown title="Videos" id="nav-dropdown">
                        {Object.keys(pagesNameVideos).map((item,index) =>
                            <LinkContainer to={`/main/${item}`}  key={index}>
                                <NavDropdown.Item eventKey={item}>
                                    {pagesNameVideos[item]}
                                </NavDropdown.Item>
                            </LinkContainer>
                        )}
                    </NavDropdown>
                </Nav>

                    </Navbar.Collapse>
                    <Logo size={''}/>
                    <button className={`btn btn-outline-light logout-padding`} onClick={handleLogOut}>
                       Log&nbsp;out
                    </button>

                </Navbar>

            </>
        );
    } else {
        return (
            <div className={'justify-content-center align-items-center fixed-top'}>
                <Nav fill variant="tabs" defaultActiveKey={`${props.activePageDefaultPasExp.link}`} onSelect={handleSelectPasswordExpired}>
                    <Nav.Item>
                        <LinkContainer to={'/expired'}><Nav.Link eventKey="expired">Change
                            Password</Nav.Link></LinkContainer>
                    </Nav.Item>
                    <Nav.Item className={style.logout}>
                        <LinkContainer to={'/login'}><Nav.Link className={style.logoutPadding} onClick={handleLogOut}>Log out</Nav.Link></LinkContainer>
                    </Nav.Item>
                </Nav>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        activePageDefault : state.switcher.fullPages,
        activePageDefaultPasExp : state.switcher.expirationPages,
        isPasswordExpired: state.checkIn.expiration
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logIn: isLogin,
        switchPage: switchActivePage,
        switchVideosActivePage,
        currentlyModeratedAccount,
        allModerators,
        switchPagePasswordExpired: switchActivePageExpPassword,
        footerInfo: clientRequest
    },dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Navigation);