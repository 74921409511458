import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {clientRequest} from "../actions/StandartRequestActions";

function Footer(props) {

    useEffect(() => {
        if (props.info !== 'pending...') {
            const timer = setTimeout(() => {
                props.footerInfo('')
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [props, props.info]);

    const renderSpin = () => {
        return (
            <div className={'footer'}>
                <p className={'footer-logo'}>VideoTalkChat.com &#169; 2020</p>
                <img className={'spin-image'}
                    src={`${process.env.PUBLIC_URL}/spin.gif`}
                    alt={'pending...'}/>
            </div>
        );
    };

    const renderMessage = () => {
        return (
            <div className={'footer'}>
                {props.info ? <p className={'footer-logo footer-message'}>{props.info}</p>
                    : <p className={'footer-logo'}>VideoTalkChat.com &#169; 2020</p>}
            </div>
        );
    };

    return props.info === 'pending...' ? renderSpin() : renderMessage();

}

function mapStateToProps(state) {
    return {
        info: state.userLogin.info
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        footerInfo: clientRequest
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);