import React, {useEffect, useRef} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {getFrameId} from "../actions/setting/FrameIdAction";
import {frameIdText} from "../const/Constant";
import {clientRequest} from "../actions/StandartRequestActions";


const PluginCode = (props) => {

    useEffect(() => {
        if (!props.frameID) {
            props.getFrameId(props.user.email)
        }
    }, []);

    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        //e.target.focus();
        props.clientRequest('Plugin code was copied successfully')
    }

    return (
        <>
            <h3 className={'mb-4'}>Just copy the embed code and paste it into your site code</h3>
            <div className={'main-shadow'}><textarea className={`plugin-container text-left bg-dark`} style={{fontSize: 1.3 + 'rem'}}
                           ref={textAreaRef}
                           readOnly
                           value={props.frameID ? frameIdText(props.frameID) : 'wait please...'}
            /></div>
            {
                document.queryCommandSupported('copy') &&
                <div className={'mt-3'}>
                    <Button variant={'info'} onClick={copyToClipboard}>Copy to clipboard</Button>
                </div>
            }
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFrameId,
        clientRequest
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        frameID: state.result.frameID
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PluginCode);