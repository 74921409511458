import React, {useEffect, useRef} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactPlayer from "react-player";
import {clientRequest} from "../actions/StandartRequestActions";
import {getVideoBetweenDate} from "../actions/videos/FindVideosByDateCreationAction";
import style from '../styles/Video.module.css';
import boardStyle from '../styles/Board.module.css';
import {getPlayerRef, isVideoPlay} from "../actions/SwitchPageAction";

const Video = (props) => {

    const playerRef = useRef(null);

    const playerStyle = {
        color: 'white',
        backgroundImage: 'black'
    };

    useEffect(() => {
        props.getPlayerRef(playerRef);
    },[playerRef]);

    return (
        <div className={style.player}>
            { props.video.videoUrl ?
                <ReactPlayer ref={playerRef}  url={props.video.videoUrl}
                            playing={props.isPlaying}
                            controls={true}
                            muted
                            style={playerStyle}
                            onPlay={() => props.isVideoPlay(true)}
                            onEnded={() => props.isVideoPlay(false)}
                            onPause={() => props.isVideoPlay(false)}
                />
               : <p className={`${boardStyle.board} ${boardStyle.message}`}> {props.video.message ? props.video.message : ''}</p>
            }
        </div>
         );
    };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendInfo: clientRequest,
        getVideoBetweenDate,
        getPlayerRef,
        isVideoPlay
    },dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        video: state.result.video,
        isPlaying: state.switcher.playing
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(Video);