import {clientRequest, responseError, responseSuccess} from "../StandartRequestActions";
import {isLogin, isPasswordExpired} from "../IsLoginAction";
import {urlApiAccount} from '../../const/Constant.js'
import {switchActivePageExpPassword} from "../SwitchPageAction";
import {handleResponseHeader} from "../../const/Constant";
import {currentlyModeratedAccount} from "../ResultAction";

export const loginUser = (user) => {
    let _token = null;
    let errorMessage = 'Wrong data';
    try {
         _token = `Basic ${btoa(`${user.login}:${user.password}`)}`;
    } catch (e) {
         errorMessage = 'Wrong data during taping password. Please, check keyboard layout';
    }
    const init = {
        method: 'POST',
        headers: new Headers({
            'Authorization': `${_token}`
        })
    };

    return(dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAccount}/login`, init)

            .then(response => {
                return  handleResponseHeader(response, user.login);
            })
            .then(response => response.json(), e => dispatch(responseError(e)))
            .then(data => {
                console.log(data);
                if (data.user) {
                    console.log(data.expired);
                    localStorage.setItem(`videocommentsuser${data.user.email}`,JSON.stringify(data.user));
                    dispatch(responseSuccess(data.user));
                    if (!data.expired) {
                        localStorage.setItem(`videocommentsexpireduser${data.user.email}`, '0');
                        dispatch(isPasswordExpired(false));
                        dispatch(clientRequest(''));
                        dispatch(isLogin(true));
                        dispatch(currentlyModeratedAccount(data.user.email));
                    } else {
                        localStorage.setItem(`videocommentsexpireduser${data.user.email}`, '1');
                        dispatch(clientRequest("Password expired"));
                        dispatch(switchActivePageExpPassword('expired'));
                        dispatch(isPasswordExpired(true));
                        dispatch(isLogin(true));
                    }
                }else{
                        dispatch(clientRequest(data.message === '' ? errorMessage : data.message));
                }
            })
            .catch(e => console.log(e))
    }
};