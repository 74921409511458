import React, {useEffect, useState} from 'react';
import {addModeratorMessage, pagesNameSettings, removeModeratorMessage} from "../../const/Constant";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getVideoModerators} from "../../actions/setting/ModeratorsAction";
import style from "../../styles/Video.module.css";
import {addModerator} from "../../actions/setting/AddNewModeratorAction";
import {removeModerator} from "../../actions/setting/RemoveModeratorAction";
import {clientRequest} from "../../actions/StandartRequestActions";
import MessageWindow from "../MessageWindow";

const Moderators = (props) => {

    const [modalShowRemove, setModalShowRemove] = useState({flag: false, buttonEventValue: null });
    const [modalShowAdd, setModalShowAdd] = useState({flag: false, buttonEventValue: null });
    const [moderator, setModerator] = useState(null);

    useEffect(() => {
        if (props.moderators.length === 0) {
            props.getVideoModerators(props.user.email);
        }
    }, []);

    useEffect(() => {
        setModerator(props.moderators[0])
    }, [props.moderators]);

    const handleRemove = (value) => {
        if (props.moderators[value] !== undefined) {
            props.removeModerator(props.user.email, props.moderators[value].email);
        }
    };

    const handleCloseRemoveModalWindow = () => {
        setModalShowRemove({flag: false, buttonEventValue: null });
    };

    const handleRemoveClick = (value) => {
        setModalShowRemove({flag: true, buttonEventValue: value});
    };

    const handleCloseAddModalWindow = () => {
        setModalShowAdd({flag: false, buttonEventValue: null });
    };

    const handleAddClick = (value) => {
        if (value) {
            setModalShowAdd({flag: true, buttonEventValue: value});
        }
    };

    const handleSelect = (e) => {
        setModerator(props.moderators[e.target.value]);
    };

    const handleAddModerator = (moderEmail) => {
        let moderator = {
            avatar: 'https://www.gravatar.com/avatar/0?d=mp',
            email: moderEmail,
            name: '',
            phone: '',
        };
        if (moderEmail && props.user.email !== moderEmail) {
            props.addModerator(props.user.email, moderator);
        }else {
            props.clientRequest('You already admin of this account');
        }
    };

    const renderAddModerator = () => {
        return (
            <div className={style.bottomForm}>
                <label className={'d-block mt-3'}>
                add moderator:
                <form className={'d-flex'} onSubmit={(e) => {
                    e.preventDefault();
                    handleAddClick(e.target.email.value.trim())
                }}>
                    <input className={'input-style'}
                           type="text"
                           name={'email'}
                           placeholder={'type moderator email'}
                           minLength={5}/>
                    <button className={'btn btn-outline-success custom-success'}
                            type={'submit'}>Add
                    </button>
                    <MessageWindow show={modalShowAdd.flag}
                                   onHide={handleCloseAddModalWindow}
                                   message={addModeratorMessage}
                                   perform={() => modalShowAdd ?
                                       handleAddModerator(modalShowAdd.buttonEventValue) : ''}
                    />
                </form>
            </label>
            </div>
        )
    };

    const renderSelect = () => {
        return (
            <div className={style.topForm}>
                <label className={'d-block mt-3'}>
                moderator :
                <form className={'d-flex'}
                      onSubmit={(e) => {
                          e.preventDefault();
                          handleRemoveClick(e.target.moderatorsList.value)
                      }}
                >
                    <select name="moderatorsList"
                            className={`${style.moderator} input-style`}
                            onChange={handleSelect}>
                        {props.moderators.map((item, index) =>
                            <option value={index} key={index}>
                                {`email: ${item.email}`}
                            </option>
                        )}
                    </select>
                    <button className={'btn btn-outline-danger custom-danger'}
                            type={'submit'}
                    >
                        Remove
                    </button>
                    <MessageWindow show={modalShowRemove.flag}
                                   onHide={handleCloseRemoveModalWindow}
                                   message={removeModeratorMessage}
                                   perform={() => modalShowRemove ?
                                       handleRemove(modalShowRemove.buttonEventValue) : ''}
                    />
                </form>
            </label>
            </div>
        )
    };

    if (props.moderators.length === 0) {
        return (
            <div className={'panel-container'}>
                <h2>{pagesNameSettings.moderators}</h2>
                <h2>{`of ${props.user.email}`}</h2>
                {renderAddModerator()}
            </div>
        );
    } else if (moderator) {
        return (
            <div className={'panel-container'}>
                <h2>{pagesNameSettings.moderators}</h2>
                <h2>{`of ${props.user.email}`}</h2>

                {renderSelect()}
                <div className={'component'}>
                    <h3>Moderator:</h3>
                    <p><img src={moderator.avatar} alt={'avatar'}/></p>
                    <p>email : {moderator.email}</p>
                    <p>name : {moderator.name}</p>
                    <p>phone : {moderator.phone}</p>
                </div>

                {renderAddModerator()}
            </div>
        );
    } else {
        return (
            <div className={'panel-container'}>
                {renderSelect()}
            </div>
        );
    }
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getVideoModerators,
        addModerator,
        removeModerator,
        clientRequest
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        moderators: state.result.moderators,
        moderated: state.result.moderatedAccounts
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Moderators);