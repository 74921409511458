import React, {useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import {changeVideoSafety} from "../actions/videos/ChangeVideoSafeAction";
import MessageWindow from "./MessageWindow";
import {changeSafetyMessage, changeNonSafetyMessage} from "../const/Constant";

const VideoChangeSafety = (props) => {

    const [modalShow, setModalShow] = useState(false);
    const [safety, setSafety] = useState(false);

    const handleSafetyClick = (choice) => {
        setModalShow(true);
        setSafety(choice);
    };

    const handleCloseModalWindow = () => {
        setModalShow(false);
    };

    const handleSafety = () => {
        let video = {
            email: props.user.email,
            id: props.video.id,
            videoSafe: safety,
            index: props.list.indexOf(props.video),
            list: props.list,
            moderated: props.account
        };
        props.changeVideoSafety(video);
    };

    return(
        <div className={'d-flex'}>
            <Button variant="success" disabled={props.video.videoSafe} onClick={() => handleSafetyClick(true)}>Safety</Button>
            <Button variant={`outline-${props.currentPage === 'safe' ? 'light' : 'secondary'}`}
                    disabled={!props.video.videoSafe}
                    onClick={() => handleSafetyClick(false)}>{props.currentPage ==='safe' ? 'Non-safety' : 'Non'}</Button>

        <MessageWindow show={modalShow}
    onHide={handleCloseModalWindow}
    message={safety ? changeSafetyMessage : changeNonSafetyMessage}
    perform={handleSafety}
    />
    </div>
    );

};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeVideoSafety
    },dispatch);
}

function mapStateToProps(state) {
    return {
        video: state.result.video,
        user: state.userLogin.user,
        list: state.result.tempList,
        currentPage: state.switcher.fullPages.link,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(VideoChangeSafety);

