import React, {useEffect} from 'react';
import {pagesNameVideos} from "../../const/Constant";
import style from "../../styles/Video.module.css";
import Video from "../Video";
import VideoInfo from "../VideoInfo";
import {bindActionCreators} from "redux";
import {getAllOrigins} from "../../actions/setting/AllOriginsaction";
import {connect} from "react-redux";
import {getVideosForModeration} from "../../actions/videos/VideosForModerationAction";
import MoreActionButton from "../MoreActionButton";
import ModeratedAccount from "../ModeratedAccount";

const VideosForModeration = (props) =>{

    useEffect(() => {
        if (props.origins.length === 0) {
            props.getAllOrigins({
                email: props.user.email,
                moderatedAccount:  props.account
            });
        }
    },[]);

    const handleVideosForModeration = (e) => {
        e.preventDefault();
        let origin = {
            email: props.user.email,
            origin: e.target.originsList.value,
            moderated: props.account
        };
        if (origin.email && origin.origin){
            props.getVideosForModeration(origin);
        }
    };

        return (
            <div className={'panel-container'}>
                <h2>{`${pagesNameVideos.moderation}`}</h2>
                <ModeratedAccount/>
                <div className={style.container}>
                    <Video/>
                    <VideoInfo/>
                </div>
                <MoreActionButton/>
                <div>
                    <form onSubmit={handleVideosForModeration} className={style.bottomForm}>
                        <label className={'d-flex mb-0'}>
                            origin :
                            <select name="originsList" className={style.origins}>
                                {props.origins.map((item,index) =>
                                    <option value={`${item}`} key={index}>
                                        {item}
                                    </option>
                                )}
                            </select>
                        </label>
                        <button className={'btn btn-info'} type={'submit'}>Get video</button>
                    </form>
                </div>
            </div>
        );
};

function mapStateToProps(state) {
    return {
        origins: state.result.origins,
        user: state.userLogin.user,
        moderated: state.result.moderatedAccounts,
        account: state.result.currentlyModeratedAccount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getVideosForModeration,
        getAllOrigins
    },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VideosForModeration);