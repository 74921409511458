import React, { useRef, useState} from 'react';
import {bindActionCreators} from "redux";
import {clientRequest} from "../actions/StandartRequestActions";
import {connect} from "react-redux";
import style from '../styles/Video.module.css';
import {Popover} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const VideoInfo = (props) => {

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [direction, setDirection] = useState('right');
    const ref = useRef(null);

    // useEffect(() => {
    //
    //     if (show) {
    //         window.addEventListener('click', function (event) {
    //             console.log('blur eve')
    //             setShow(false);
    //         })
    //     }
    // },[show]);

    const popover = (
        <Popover id="popover-contained" >
            <Popover.Title as="h3">about video:</Popover.Title>
            <Popover.Content>
                <p><span className={style.titleMore}>id:</span>{` ${props.video.id}`}</p>
                <p><span className={style.titleMore}>file name:</span>{` ${props.video.fileName}`}</p>
                <p><span className={style.titleMore}>duration:</span>{` ${props.video.duration} sec`}</p>
                <p><span className={style.titleMore}>size:</span>{` ${(+ props.video.size / 1000000).toFixed(2)}MB`}</p>
                <p><span className={style.titleMore}>origin:</span>{` ${props.video.origin}`}</p>
                <p><span className={style.titleMore}>url:</span>{` ${props.video.videoUrl}`}</p>
                <p><span className={style.titleMore}>reported:</span>{` ${props.video.videoReported ? 'yes' : 'no'}`}</p>
                <p>{props.video.safetyChangedBy ? <span className={style.titleMore}>safety changed by:</span> : ''} {props.video.safetyChangedBy ? ` ${props.video.safetyChangedBy}` : ''}</p>
                <p>{props.video.lastSafetyChange ? <span className={style.titleMore}>last safety change:</span> : ''} {props.video.lastSafetyChange ? ` ${props.video.lastSafetyChange.split('T')[0]}` : ''}</p>
                {/*<p><span className={style.titleMore}>answers:</span>{` ${props.video.answersCount}`}</p>*/}
                <p><span className={style.titleMore}>created:</span>{` ${props.video.dateCreated}`}</p>
            </Popover.Content>
        </Popover>
    );

    const handleClick = (event) => {
        let width =  document.getElementsByTagName("html")[0].scrollWidth;
        if (width > 1485){
            setDirection('right');
        }else if (width > 991 && width < 1486){
           setDirection('top');
        }else{
            setDirection('auto');
        }
        setShow(!show);
        setTarget(event.target);
    };

    if (props.video.videoUrl) {
        return (
            <div className={style.info}>
                <h3>Selected video:</h3>
                <div className={style.info}>
                    <p>author: {props.video.author}</p>
                    <p>video safe: {props.video.videoSafe ? 'yes' : ' no'}</p>
                    <label htmlFor="safe">
                        <select id={'safe'} className={style.selectInfo}>
                            <option>nudity safe: {props.video.videoSafetyFeatures ? round(props.video.videoSafetyFeatures.nudsafe * 100) : ''}%</option>
                            <option>weapon: {props.video.videoSafetyFeatures ? round(props.video.videoSafetyFeatures.wpn * 100) : ''}%</option>
                            <option>alcohol: {props.video.videoSafetyFeatures ? round(props.video.videoSafetyFeatures.alc * 100) : ''}%</option>
                            <option>drugs: {props.video.videoSafetyFeatures ? round(props.video.videoSafetyFeatures.drg * 100) : ''}%</option>
                            <option>offensive: {props.video.videoSafetyFeatures ? round(props.video.videoSafetyFeatures.offsv * 100) : ''}%</option>
                        </select>
                    </label>
                    <p>likes: {props.video.likes}</p>
                    <p>dislikes: {props.video.dislikes}</p>

                    <div ref={ref}>
                        <OverlayTrigger  trigger={['click']} placement={direction} overlay={popover}
                            show={show}
                            target={target}
                            container={ref.current}
                            containerPadding={20}
                        >
                            <p className={style.more} onClick={handleClick}>more info...</p>
                        </OverlayTrigger>
                    </div>

                </div>
                <p>{props.video.dateCreated ? `created: ${props.video.dateCreated.split('T')[0]}` : ''}</p>
            </div>
        );
    } else {
        return (
            <div className={style.info}>
                <h3>Selected video:</h3>
            </div>
        );
    }

};

function round(number) {
  return (Math.round(number * 100)/100).toFixed(2);
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendInfo: clientRequest
    },dispatch);
}

function mapStateToProps(state) {
    return {
        video: state.result.video,
        currentPage: state.switcher.fullPages.link
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(VideoInfo);

