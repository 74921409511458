import React, {useEffect, useState} from 'react';
import {pagesNameSettings, safetyAdjustmentsView, saveSafetyAdjustmentsMessage} from "../../const/Constant";
import {Button, Dropdown, DropdownButton} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getSafetyAdjustments} from "../../actions/setting/SafetyAdjustmentsAction";
import {updateSafetyAdjustments} from "../../actions/setting/UpdateSafetyAdjustmentsAction";
import style from "../../styles/Video.module.css";
import styleBoard from "../../styles/Board.module.css";
import MessageWindow from "../MessageWindow";
import ModeratedAccount from "../ModeratedAccount";


const SafetyAdjustments = (props) => {

    const [currentlyRangeArray, setCurrentlyRangeArray] = useState(null);
    const [adjustments, setAdjustments] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const handleCloseModalWindow = () => {
        setModalShow(false);
    };

    const handleSaveClick = () => {
        setModalShow(true);
    };

    useEffect(() => {
        if (props.videoSafety.length === 0){
            props.getSafetyAdjustments({
                email: props.user.email,
                moderatedAccount:  props.account
            });
        }
    },[]);

    useEffect(() => {
        props.getSafetyAdjustments({
            email: props.user.email,
            moderatedAccount:  props.account
        });
    },[props.account]);

    useEffect(() => {
        console.log(props.videoSafety)
            if (props.videoSafety.length !== 0){
            setCurrentlyRangeArray(props.videoSafety);
        }
    },[props.videoSafety]);

    useEffect(() => {
        console.log(currentlyRangeArray)
        if (currentlyRangeArray !== null && currentlyRangeArray.length !== 0){
            setAdjustments(arrayPushAdjustments());
        }
    },[currentlyRangeArray]);

    function popover(adjustment,loadedRange) {
        return (
            <Popover id={`popover-adjustment-${loadedRange}`}>
                <Popover.Title as="h3">{`${adjustment.title}, last saved : ${loadedRange}%`}</Popover.Title>
                <Popover.Content>
                    maximum
                    safety <strong>{adjustment.title === 'nudity safe' ? adjustment.max : adjustment.min}</strong>,
                    minimum
                    safety <strong>{adjustment.title === 'nudity safe' ? adjustment.min : adjustment.max}</strong>
                </Popover.Content>
            </Popover>
        )
    }

    function rangeAdjustments (index) {
        return (
            <Form>
                <Form.Group controlId={`safetyAdjustmentsRangeCustom${index}`}>
                    <OverlayTrigger placement={"right"} overlay={popover(safetyAdjustmentsView[index],(props.videoSafety[index]))}>
                        <Form.Label>{`${safetyAdjustmentsView[index].title}: ${currentlyRangeArray[index]}%`}</Form.Label>
                    </OverlayTrigger>
                    <Form.Control type="range"
                                  min={`${safetyAdjustmentsView[index].min}`}
                                  max={safetyAdjustmentsView[index].max}
                                  step={'1'}
                                  value={currentlyRangeArray[index]}
                                  disabled={props.account !== props.user.email}
                                  custom
                                  onChange={(e) => handleRange(e, index)}
                    />
                </Form.Group>
            </Form>
        )
    }

    function arrayPushAdjustments() {
        let array = [];
        for (const index in safetyAdjustmentsView) {
            array.push(rangeAdjustments(index));
        }
        return array;
    }

    const handleRange = (e, index) => {
        let arr = {...currentlyRangeArray};
        arr[index] = + e.target.value;
        setCurrentlyRangeArray(arr);
        setAdjustments(arrayPushAdjustments());
        setDisableSaveButton(false);
    };

    const handleReset = (flag) => {
        let array = {};
        for (const property in safetyAdjustmentsView) {
            array[property] = flag === 'default' ?
                safetyAdjustmentsView[property].defaultRange : property === 'minnudbound' ?
                    safetyAdjustmentsView[property].max : safetyAdjustmentsView[property].min;
        }
        setCurrentlyRangeArray(array);
        setAdjustments(arrayPushAdjustments());
        setDisableSaveButton(false);
    };

    const handleLastSaved = () => {
        setCurrentlyRangeArray(props.videoSafety);
        setAdjustments(arrayPushAdjustments());
        setDisableSaveButton(false);
    };

    const handleSaveAdjustments = () => {
        if (currentlyRangeArray.length !== 0) {
            let rangeArray = {...currentlyRangeArray};
            for (const property in rangeArray) {
                rangeArray[property] = rangeArray[property] / 100;
            }
            const update = {
                email: props.user.email,
                data: rangeArray
            };
            props.updateSafetyAdjustments(update);
            setDisableSaveButton(true);
        }
    };

    if (adjustments) {
        return (
                <div className={'panel-container'}>
                    <h2>{`${pagesNameSettings.safetyAdjustments}`}</h2>
                    <ModeratedAccount/>
                    {/*<Board info={adjustments}/>*/}
                    <div className={'board-shadow'}>
                        <div className={styleBoard.board}
                             style={{width: 48 + 'rem', height: 29 + 'rem'}}>
                            <ol>
                                {adjustments.map((item, index) => (<li key={index}>{item}</li>))}
                            </ol>
                        </div>
                    </div>
                    <div className={style.bottomForm}>
                        {props.account === props.user.email ?
                            <Button variant={'info'}
                                    disabled={disableSaveButton}
                                    onClick={handleSaveClick}>Save safety adjustments</Button>
                            : ''}
                        <MessageWindow show={modalShow}
                                       onHide={handleCloseModalWindow}
                                       message={saveSafetyAdjustmentsMessage}
                                       perform={handleSaveAdjustments}
                        />
                        {props.account === props.user.email ?
                            <DropdownButton
                                className={style.safetyMore}
                                variant="secondary"
                                title="More action ..."
                                id="input-group-dropdown-safety">
                                <Dropdown.Item href="#" onClick={() => handleReset('default')}>Reset to
                                    default</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={handleLastSaved}>Reset to last saved</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => handleReset('max')}>Max safety</Dropdown.Item>
                                <Dropdown.Divider/>
                            </DropdownButton>
                            : ''}
                    </div>
                </div>
        );
    } else {
        return (
            <div>
                <h2>{pagesNameSettings.safetyAdjustments}</h2>
            </div>
        );
    }

};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSafetyAdjustments,
        updateSafetyAdjustments
    },dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        videoSafety: state.result.safety,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(SafetyAdjustments);