export const VIDEO_LIST = 'VIDEO_LIST';
export const TEMP_VIDEO_LIST = 'TEMP_VIDEO_LIST';
export const SELECTED_VIDEO = 'SELECTED_VIDEO';
export const ALL_ORIGINS = 'ALL_ORIGINS';
export const SAFETY_ADJUSTMENTS = 'SAFETY_ADJUSTMENTS';
export const ALL_MODERATORS = 'ALL_MODERATORS';
export const MODERATED_ACCOUNTS = 'MODERATED_ACCOUNTS';
export const CURRENTLY_MODERATED_ACCOUNT = 'CURRENTLY_MODERATED_ACCOUNT';
export const FRAME_ID = 'FRAME_ID';
export const ALL_NOT_CONFIRMED_ORIGINS = 'ALL_NOT_CONFIRMED_ORIGINS';

export const videoList = (data) => ({
    type: VIDEO_LIST,
    payload: data
});

export const tempVideoList = (data) => ({
    type: TEMP_VIDEO_LIST,
    payload: data
});

export const selectedVideo = (data) => ({
    type: SELECTED_VIDEO,
    payload: data
});

export const allOrigins = (origins) => ({
    type: ALL_ORIGINS,
    payload: origins

});

export const allNotConfirmedOrigins = (origins) => ({
    type: ALL_NOT_CONFIRMED_ORIGINS,
    payload: origins

});

export const safetyAdjustmentsFromAdmin = (safetyData) => ({
    type: SAFETY_ADJUSTMENTS,
    payload: safetyData

});

export const allModerators = (moderators) => ({
    type: ALL_MODERATORS,
    payload: moderators

});

export const moderatedAccounts = (accounts) => ({
    type: MODERATED_ACCOUNTS,
    payload: accounts

});

export const currentlyModeratedAccount = (moderated) => ({
    type: CURRENTLY_MODERATED_ACCOUNT,
    payload: moderated

});

export const frameId = (id) => ({
    type: FRAME_ID,
    payload: id

});