import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {allModerators} from "../ResultAction";
import {removeModeratedAccount} from "./RemoveModeratedAccountAction";

export const removeModerator = (email,moderator) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    const init = {
        method: 'DELETE',
        headers: headers,
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${email}/moderator/${moderator}`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    console.log(data);
                    dispatch(removeModeratedAccount(email,moderator));
                    if (data.length !== 0) {
                        dispatch(allModerators(data));
                        dispatch(clientRequest(`moderators data was received`));
                    }else{
                        dispatch(allModerators([]));
                        dispatch(clientRequest(`no moderators found`));
                    }
                } else {
                    console.log(data.message)
                    if (data.message.includes('not found')) {
                        dispatch(clientRequest(`User with email: ${moderator} not exist`));
                    } else {
                        dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                    }
                }
            })
            .catch(e => {
                authorizationResponseStatus(responseStatus, email, dispatch);
            });
    }
};