import React from 'react';
import {connect} from "react-redux";
import User from "./User";
import {clientRequest} from "../../actions/StandartRequestActions";
import {bindActionCreators} from "redux";
import {changePassword} from "../../actions/account/ChangePasswordAction";

function ChangePassword(props) {

    const submitPassword = (e) => {
        e.preventDefault();
        let user = {
            email: props.user.email,
            newPassword: e.target.new.value.trim(),
            oldPassword: e.target.old.value.trim()
        };
        if (user.email && user.newPassword && user.oldPassword) {
            props.changePassword(user);
        }
    };

    return (
        <div className={'d-flex align-items-center'}>
            <div className={'component'}>
                <h2>Change Password</h2>
                <User userClass={'component'}/>
                <form onSubmit={submitPassword}>
                    <input type="password"
                           name={'old'}
                           placeholder={'type old password'}
                           minLength={10}
                    />
                    <input type="password"
                           name={'new'}
                           placeholder={'new password, 10 character min'}
                           minLength={10}/>
                    <button className={'btn-info'}
                            type={'submit'}>Change password
                    </button>
                </form>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendInfo: clientRequest,
        changePassword
    },dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ChangePassword);