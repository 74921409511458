import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {allNotConfirmedOrigins, allOrigins} from "../ResultAction";

export const getNotConfirmedOrigins = (email) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);

    const init = {
        method: 'GET',
        headers: headers
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${email}/origins/notconfirmed`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    dispatch(allNotConfirmedOrigins(data));
                    dispatch(clientRequest(data.length > 0 ? `total: ${data.length} not confirmed ${data.length === 1 ? 'origin' : 'origins'}` : ''));
                }else{
                    //dispatch(allOrigins([]));
                    //dispatch(clientRequest(`no origins found`));
                }
            })
            .catch(() => {
                authorizationResponseStatus(responseStatus, email, dispatch);
            });
    }
};