import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {moderatedAccounts} from "../ResultAction";

export const registerNewAdminPanel = (email) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    const init = {
        method: 'POST',
        headers: headers
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${email}`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    console.log(data);
                        dispatch(moderatedAccounts(data.moderatedAccounts));
                        dispatch(clientRequest(`admin panel was created`));
                } else {
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                }
            })
            .catch(e => {
                authorizationResponseStatus(responseStatus, email, dispatch);
            });
    }
};