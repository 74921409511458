import {clientRequest, responseError, responseSuccess} from "../StandartRequestActions";
import {urlApiAccount} from '../../const/Constant.js'
import {authorizationResponseStatus, handleResponseHeader} from "../../const/Constant";

export const changeUserRoles = (user) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${user.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    const init = {
        method: user.method,
        headers: headers
    };
    return (dispatch)=>{
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAccount}/user/${user.login}/role/${user.role}`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, user.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e)))
            .then(data => {
                console.log(data);
                if (responseStatus === 200) {
                    dispatch(clientRequest('user roles was changed successfully'));
                    if (user.email === data.email) {//checking of same user, for locating in storage in this computer
                        localStorage.setItem(`videocommentsuser${data.email}`,JSON.stringify(data));
                        dispatch(responseSuccess(data));
                    }
                }else{
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, user.email, dispatch);
            });
    }
};