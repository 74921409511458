import {clientRequest, responseError} from "../StandartRequestActions";
import {urlApiAccount} from '../../const/Constant.js'
import {registerNewAdminPanel} from "./RegisterNewAdminPanelAction";
import {loginUser} from "./LoginUserAction";
import {handleResponseHeader} from "../../const/Constant";

export const registerNewUser = (register) => {
    let responseStatus = null;
    let headers = new Headers();
    let _token = null;
    let timerId = null;
    headers.append('Content-Type', 'application/json');
    const init = {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: `${JSON.stringify(register)}`
    };
    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAccount}`, init)
            .then(response => {
                responseStatus = response.status;
                return  response;
            })
            .then(response => response.json(), e => dispatch(responseError(e)))
            .then(user => {
                console.log(user);
               if (user.email) {
                   dispatch(clientRequest(`User ${user.email} was successfully registered`));
                   dispatch(loginUser({login:user.email, password: register.password}));
                    //Recursive setTimeOut - check for login action, if will be complete
                   timerId = setTimeout(function waitForLogin() {
                       _token = localStorage.getItem(`videocommentsxtokenuser${user.email}`);
                       timerId = setTimeout(waitForLogin, 100);
                       if (_token !== null){
                           clearTimeout(timerId);
                           dispatch(registerNewAdminPanel(user.email));
                       }
                   }, 100);

                }else if (responseStatus === 409){
                   //dispatch(clientRequest(user.message === '' ? "Wrong data" : user.message));
                   dispatch(clientRequest(`User with email ${register.email} is already registered`))
               }else {
                   dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
               }
            })
            .catch(e => console.log(e));
    }
};

