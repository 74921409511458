import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiVideo} from "../../const/Constant";
import {selectedVideo, tempVideoList, videoList} from "../ResultAction";

export const getVideosForModeration = (origin) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${origin.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    headers.append('X-Custom-Origin', `${origin.origin}`);
    if (origin.email !== origin.moderated) {
        headers.append('X-Moderated-Account', origin.moderated);
    }
    const init = {
        method: 'GET',
        headers: headers,
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiVideo}/${origin.email}/moderation`, init)
            .then(response => {
               responseStatus = response.status;
               return  handleResponseHeader(response, origin.email);
            })
            .then(response => response.json(), e => {
                dispatch(responseError(e.toString().replace('TypeError:','')))
            })
            .then(data => {
                if (responseStatus === 200){
                    console.log(data);
                    dispatch(videoList(data));
                    dispatch(tempVideoList(data));
                    dispatch(selectedVideo(data.length > 0 ? data[0] : ''));
                    dispatch(clientRequest(`${data.length} video${data.length > 1 ? 's' : ''} received`));
                }else{
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus}), try again`));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, origin.email, dispatch);
            });
    }

};