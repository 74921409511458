import React from 'react';
import {connect} from "react-redux";
import {Dropdown, DropdownButton} from "react-bootstrap";
import style from "../styles/Video.module.css";
import VideoChangeSafety from "./VideoChangeSafety";
import DeleteVideoButton from "./DeleteVideoButton";
import VideosList from "./VideosList";

const MoreActionButton = (props) => {
        return (
            <div className={style.action}>
                <VideosList/>
                {props.currentPage !== 'remove' && props.currentPage !== 'safe'
                    && props.selectedVideo.videoUrl && props.list.length > 0 ?
                    <DropdownButton className={style.dropdownButton}
                                    variant="outline-secondary"
                                    title="More action ..."
                                    id="input-group-dropdown-1">
                        <Dropdown.Item href="#"><VideoChangeSafety/></Dropdown.Item>
                        <Dropdown.Item href="#"><DeleteVideoButton/></Dropdown.Item>
                        <Dropdown.Divider/>
                    </DropdownButton>
                    :
                    props.currentPage === 'remove' && props.selectedVideo ? <DeleteVideoButton/>
                    :
                    props.currentPage === 'safe' && props.selectedVideo ? <VideoChangeSafety/>
                    :
                    ''}
            </div>
        );
    };


function mapStateToProps(state) {
    return {
        selectedVideo: state.result.video,
        list: state.result.tempList,
        currentPage: state.switcher.fullPages.link
    };
}

export default connect(mapStateToProps)(MoreActionButton);