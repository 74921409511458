import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getVideoBetweenDate} from "../actions/videos/FindVideosByDateCreationAction";
import style from "../styles/Video.module.css";

const VideosByDateCreation = (props) => {

    const getVideoBetweenDate = (e) => {
        e.preventDefault();
        let dates = {
            email: props.user.email,
            from: e.target.datefrom.value,
            to: e.target.dateto.value,
            moderated: props.account
        };
        if (dates.email && dates.from && dates.to) {
            props.getVideoBetweenDate(dates);
        }
    };

    return (
        <div>
            <form onSubmit={getVideoBetweenDate} className={style.bottomForm}>
                <label className={'mb-0'}>
                    from :
                    <input className={'d-inline mb-0 mr-1'}
                           style={{height: 2.8 + 'rem'}}
                           type="date"
                           name="datefrom"
                           min="2020-01-01"
                           required/>
                </label>
                <label className={'mb-0'}>
                    to :
                    <input className={'d-inline mb-0'}
                           style={{height: 2.8 + 'rem'}}
                           type="date" name="dateto"
                           min="2020-01-01"
                           required/>
                </label>
                <button className={'btn btn-info'} type={'submit'}>Get video</button>
            </form>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getVideoBetweenDate,
    },dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(VideosByDateCreation);