import React from 'react';
import './App.css';
import Register from "./components/Register";
import Login from "./components/Login";
import Main from "./components/Main";
import {Route, Switch} from "react-router-dom";
import PasswordExpired from "./components/PasswordExpired";

function App() {

    return (
        <>
                <Switch>
                    <Route path={'/login'} component={Login}/>
                    <Route path={'/register'} component={Register}/>
                    <Route path={'/expired'} component={PasswordExpired}/>
                    <Route path={['/', '/main', '/main/:page']} exact
                            render={(props) => (<Main {...props}/>)}/>
                    <Route component={Main}/>
                </Switch>
        </>
    )

}

export default App;
