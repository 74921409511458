import {clientRequest, responseError, responseSuccess} from "../StandartRequestActions";
import {isLogin} from "../IsLoginAction";
import {switchActivePage} from "../SwitchPageAction";
import {urlApiAccount} from '../../const/Constant.js';
import {authorizationResponseStatus} from "../../const/Constant";

export const deleteUser = (user) => {
    let responseStatus = null;
    const _btoaToken = `Basic ${btoa(`${user.email}:${user.password}`)}`;
    const _token = localStorage.getItem(`videocommentsxtokenuser${user.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    headers.append( 'Authorization',`${_btoaToken}`);
    const init = {
        method: 'DELETE',
        headers: headers
    };
    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAccount}/user/${user.email}/`, init)
            .then(response => {
                responseStatus = response.status;
                return response;
            })
            .then(response => response.json(), e => dispatch(responseError(e)))
            .then(user => {
                console.log(user);
                if (user.email) {
                    localStorage.removeItem(`videocommentsxtokenuser${user.email}`);
                    localStorage.removeItem(`videocommentsuser${user.email}`);
                    dispatch(clientRequest(''));
                    dispatch(responseSuccess({}));
                    dispatch(isLogin(false));
                    dispatch(switchActivePage('home'));
                }else{
                    if (user.status === 401){
                        dispatch(clientRequest("Wrong password"));
                    }else {
                        dispatch(clientRequest(user.message === '' ? "Wrong data" : user.message));
                    }
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, user.email, dispatch);
            });
    }
};