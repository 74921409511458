import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {allOrigins} from "../ResultAction";

export const getAllOrigins = (accountData) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${accountData.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);

    if (accountData.email !== accountData.moderatedAccount && accountData.moderatedAccount !== '') {
        headers.append('X-Moderated-Account', accountData.moderatedAccount);
    }
    const init = {
        method: 'GET',
        headers: headers
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${accountData.email}/origins`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, accountData.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                dispatch(allOrigins(data));
                dispatch(clientRequest(`total: ${data.length} ${data.length === 1 ? 'origin' : 'origins'}`));
                }else{
                    dispatch(allOrigins([]));
                    dispatch(clientRequest(`no origins found`));
                }
            })
            .catch(() => {
                authorizationResponseStatus(responseStatus, accountData.email, dispatch);
            });
    }
};