import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiVideo} from "../../const/Constant";
import {selectedVideo, tempVideoList, videoList} from "../ResultAction";

export const getVideosByOrigin = (origin) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${origin.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    headers.append('X-Custom-Origin', `${origin.origin}`);
    if (origin.email !== origin.moderated) {
        headers.append('X-Moderated-Account', origin.moderated);
    }
    const init = {
        method: 'GET',
        headers: headers,
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiVideo}/${origin.email}/videos`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, origin.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString().replace('TypeError:',''))))
            .then(data => {
                if (responseStatus === 200){
                    let tempArray = [...data];
                    let answers;
                    for ({videoAnswers: answers} of data){
                         if(answers !== null) {
                             tempArray.push(...answers);
                         }
                    }
                    console.log(tempArray);
                    tempArray.sort((video1, video2) => new Date(video1.dateCreated) - new Date(video2.dateCreated));
                    console.log(tempArray);
                    dispatch(videoList(tempArray));
                    dispatch(tempVideoList(tempArray));
                    dispatch(selectedVideo(tempArray.length > 0 ? tempArray[0] : ''));
                    dispatch(clientRequest(`${tempArray.length} video${tempArray.length > 1 ? 's' : ''} received`));
                }else{
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus}), try again`));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, origin.email, dispatch);
            });
    }

};