import React from 'react';
import Video from "../Video";
import style from '../../styles/Video.module.css';
import VideosByDatesView from "../VideosByDatesView";
import VideoInfo from "../VideoInfo";
import {pagesNameVideos} from "../../const/Constant";
import MoreActionButton from "../MoreActionButton";
import ModeratedAccount from "../ModeratedAccount";

const ChangeVideoSafe = () => {

    return (
        <div className={'panel-container'}>
            <h2>{pagesNameVideos.safe}</h2>
            <ModeratedAccount/>
            <div className={style.container}>
                <Video/>
                <VideoInfo/>
            </div>
            <MoreActionButton/>
           <VideosByDatesView/>
        </div>
    );
};

export default ChangeVideoSafe;