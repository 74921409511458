import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import React from "react";
import {findVideo} from "../../actions/videos/FindVideoAction";
import {pagesNameVideos} from "../../const/Constant";
import style from "../../styles/Video.module.css";
import Video from "../Video";
import VideoInfo from "../VideoInfo";
import MoreActionButton from "../MoreActionButton";
import ModeratedAccount from "../ModeratedAccount";

const FindVideo = (props) => {

    const handleFindVideo = (e) => {
        e.preventDefault();
        let video = {
            email: props.user.email,
            id: e.target.video.value.trim(),
            moderated: props.account
        };
        if (video.email && video.id){
            props.findVideo(video);
        }
    };

    return (
        <div className={'panel-container'}>
            <h2>{`${pagesNameVideos.video}`}</h2>
            <ModeratedAccount/>
            <div className={style.container}>
                <Video/>
                <VideoInfo/>
            </div>
            <MoreActionButton/>
            <div>
            <form onSubmit={handleFindVideo} className={style.bottomForm}>
                <label className={'mb-0'}>
                    id :
                <input
                       className={'d-inline mb-0 input-style'}
                       type="text"
                       name={'video'}
                       placeholder={'type video id'}
                       minLength={3}
                />
                </label>
                <button className={'btn btn-info'}
                        type={'submit'}>Find video</button>
            </form>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            findVideo
        }, dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        moderated: state.result.moderatedAccounts,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(FindVideo);