import React from 'react';

const Logo = (props) => {

    return (
        <div className={props.size ? 'login-logo-container' : 'logo-container'}>
            <a className={`display-1 ${props.size}`} href={'https://www.videotalkchat.com/'} target={'_blank'}
               rel="noopener noreferrer">
                Vide<span
                className={'o-non'}>o</span>
                <img src={`${process.env.PUBLIC_URL}/bobbin.svg`}
                     className="App-logo"
                     alt="logo"/>
                Talk Chat
            </a>
        </div>

    );
};


export default Logo;