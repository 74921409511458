import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import User from "./User";
import {deleteUser} from "../../actions/account/DeleteUserAction";

function DeleteUser(props){
    const submitDelete = (e) => {
        e.preventDefault();
        let user = {
            email: props.user.email,
            password: e.target.password.value.trim(),
        };
        if (user.email && user.password) {
            props.remove(user);
        }
    };
        return (
            <div className={'d-flex align-items-center'}>
                <div className={'component'}>
                    <h2>Delete Account</h2>
                    <User userClass={'component'}/>
                    <form onSubmit={submitDelete}>
                        <input type="password" name={'password'}
                               placeholder={'type your password'}
                               minLength={10}/>
                        <button className={'btn-danger'}
                                type={'submit'}>Remove account
                        </button>
                    </form>
                </div>
            </div>
        );
    }


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            remove: deleteUser
        }, dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteUser);