import {LOGIN, PASSWORD_EXP} from "../actions/IsLoginAction";

const init = {
  login: false,
  expiration: false
};
export default function (state= init, action) {
    switch (action.type) {
        case LOGIN:
            return {...state, login: action.payload};
        case PASSWORD_EXP:
            return {...state, expiration: action.payload};
        default:
            return state;
    }
}