export const LOGIN = 'LOGIN';
export const PASSWORD_EXP = 'PASSWORD_EXP';

export const isLogin = (flag) => ({
    type: LOGIN,
    payload: flag
});

export const isPasswordExpired = (flag) => ({
    type: PASSWORD_EXP,
    payload: flag
});
