import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiVideo} from "../../const/Constant";
import {selectedVideo, tempVideoList, videoList} from "../ResultAction";

export const findVideo = (video) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${video.email}`);
    let headers = new Headers();
    headers.append('X-Token', `${_token}`);
    if (video.email !== video.moderated) {
        headers.append('X-Moderated-Account', video.moderated);
    }
    const init = {
        method: 'GET',
        headers: headers,
        mode: 'cors'
    };
    return (dispatch)=>{
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiVideo}/${video.email}/video/${video.id}`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, video.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e)))
            .then(data => {
                console.log(data);
                if (responseStatus === 200) {
                    dispatch(videoList([data]));
                    dispatch(tempVideoList([data]));
                    dispatch(selectedVideo(data.id ? data : ''));
                    dispatch(clientRequest(`video with id : ${data.id} was received`));
                }else{
                    dispatch(clientRequest(data.message === '' ? "Wrong data" : data.message));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, video.email, dispatch);
            });
    }
};