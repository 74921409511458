import React, {useEffect, useState} from 'react';
import {pagesNameSettings} from "../../const/Constant";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getAllOrigins} from "../../actions/setting/AllOriginsaction";
import {getOriginsWithRecordedVideos} from "../../actions/setting/OriginsWithRecordedVideosAction";
import ModeratedAccount from "../ModeratedAccount";
import styleBoard from "../../styles/Board.module.css";
import style from "../../styles/Video.module.css";
import Button from "react-bootstrap/Button";

const AllOrigins = (props) => {

    const[originsList,setOriginsList]=useState([]);
    const[title,setTitle]=useState('All origins');

    useEffect(() => {
        if (props.origins.length === 0) {
            props.getAllOrigins({
                email: props.user.email,
                moderatedAccount:  props.account
            });
        }
    },[]);

    useEffect(() => {
            props.getAllOrigins({
                email: props.user.email,
                moderatedAccount:  props.account
            });
    },[props.account]);

    useEffect(() => {
            let arr = [...props.origins];
            setOriginsList(arr);
    }, [props.origins]);

    const handleOriginsWithVideo = () => {
      props.getOriginsWithRecordedVideos({
          email: props.user.email,
          moderatedAccount:  props.account
      });
      setTitle('Origins with recorded videos');
    };

    const handleAllOrigins = () => {
        setTitle('All origins');
        props.getAllOrigins({
            email: props.user.email,
            moderatedAccount:  props.account
        });
    };

        return (
                <div className={'panel-container'}>
                    <h2>{`${title}`}</h2>
                    <ModeratedAccount/>
                    <div className={'board-shadow'}>
                        <div className={`${styleBoard.board} text-left`}
                             style={{width: 48 + 'rem', height: 29 + 'rem'}}>
                            <ol>
                                {originsList.map((item, index) => (<li key={index}>{item}</li>))}
                            </ol>
                        </div>
                    </div>
                    <div className={style.bottomForm}>
                        <Button className={'mr-5'} variant={'info'} onClick={handleAllOrigins}>All origins</Button>
                        {/*{props.account === props.user.email ?*/}
                        <Button variant={'success'} onClick={handleOriginsWithVideo}>With recorded videos</Button>
                        {/*: ''}*/}

                    </div>
                </div>
        );
    };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
       getAllOrigins,
       getOriginsWithRecordedVideos
    },dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        origins: state.result.origins,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(AllOrigins);