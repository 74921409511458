export const PAGE = 'PAGE';
export const PAGE_VIDEOS = 'PAGE_VIDEOS';
export const PAGE_PASSWORD_EXP = 'PAGE_PASSWORD_EXP';
export const PLAYER = 'PLAYER';
export const IS_VIDEO_PLAY = 'IS_VIDEO_PLAY';

export const switchActivePage = (link) => ({
    type: PAGE,
    payload: link
});

export const switchActivePageExpPassword = (link) => ({
    type: PAGE_PASSWORD_EXP,
    payload: link
});

export const switchVideosActivePage = (link) => ({
    type: PAGE_VIDEOS,
    payload: link
});

export const getPlayerRef = (ref) => ({
    type: PLAYER,
    payload: ref
});

export const isVideoPlay = (play) => ({
    type: IS_VIDEO_PLAY,
    payload: play
});









