import React from 'react';
import {Button, Modal} from "react-bootstrap";

const MessageWindow = (props) => {

    const handleActionButton = () => {
        props.onHide();
        props.perform();
    };

    return (
        <Modal show={props.show} onHide={() => props.onHide()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
               style={{
                   overflow: 'hidden',
                   paddingRight: 0 + 'px'
               }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.message.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {props.message.text}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'secondary'} onClick={() => props.onHide()}>Cancel</Button>
                <Button variant={props.message.buttonType} onClick={handleActionButton}>{props.message.actionButton}</Button>
            </Modal.Footer>
        </Modal>
    );

};


export default MessageWindow;