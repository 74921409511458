import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {allModerators} from "../ResultAction";
import {addModeratedAccountAction} from "./AddModeratedAccountAction";

export const addModerator = (email,moderator) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    const init = {
        method: 'POST',
        headers: headers,
        body: `${JSON.stringify(moderator)}`
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${email}/moderator`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    console.log(data);
                    if (data.length !== 0) {
                        dispatch(allModerators(data));
                        dispatch(addModeratedAccountAction(email, moderator.email));
                        dispatch(clientRequest(`moderators data was received`));
                    }else{
                        dispatch(clientRequest(`no moderators found`));
                    }
                } else {
                    console.log(data.message)
                    if (data.message.includes('not found')) {
                        dispatch(clientRequest(`User with email: ${moderator.email} not exist`));
                    } else {
                        dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                    }
                }
            })
            .catch(e => {
                authorizationResponseStatus(responseStatus, email, dispatch);
            });
    }
};