import React from 'react';
import {connect} from "react-redux";
import {responseSuccess} from "../../actions/StandartRequestActions";

function User(props) {

    if (props.user) {
        return (
            <div className={'d-flex align-items-center'}>
                <div className={props.userClass}>
                    <h2>User:</h2>
                    <p><img src={props.user.avatar} alt={'avatar'}/></p>
                    <p>email : {props.user.email}</p>
                    <p>name : {props.user.name}</p>
                    <p>phone : {props.user.phone}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className={'component'}>
                <h2>User:</h2>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.userLogin.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: user => dispatch(responseSuccess(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);