import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Navigation from "./Navigation";
import Footer from "./Footer";
import {Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {isLogin, isPasswordExpired} from "../actions/IsLoginAction";
import {responseSuccess} from "../actions/StandartRequestActions";
import {switchActivePage, switchActivePageExpPassword} from "../actions/SwitchPageAction";
import {findLocalItems, pages} from "../const/Constant";
import PasswordExpired from "./PasswordExpired";
import {getAllOrigins} from "../actions/setting/AllOriginsaction";

function Main(props) {

    useEffect(() => {
        if (props.user) {
            props.getAllOrigins({
                email: props.user.email,
                moderatedAccount:  props.account
            });
        }
    },[]);

    let page = props.match.params.page;

    if (props.user === undefined){
        let user = findLocalItems(/videocommentsuser(.*)/);
        if (user.length === 1){
            props.addUser(user[0].val);
            const exp = localStorage.getItem(`videocommentsexpireduser${user[0].val.email}`);
            if (exp === '1'){
                props.setIsPasswordExp(true);
                props.switchPagePasswordExpired('expired');
                return (
                    <div>
                        <Redirect to={`/expired`}/>
                    </div>
                )
            }else{
                props.logIn(true);
                props.navActivePage(page);
                return (
                    <div>
                        <Redirect to={`/main/${pages[page] ? page : 'home'}`}/>
                    </div>
                )
            }
        }else {
            return (
                <div>
                    <Redirect to={'/login'}/>
                </div>
            );
        }
    }

    if (props.isLogin) {
        return (
            !props.isPasswordExpired ?
            <>
                <Navigation/>
                <div className={'main-container'}>
                {props.activePage}
                </div>
                <Footer/>
            </>
         :
            <div>
                <PasswordExpired/>
            </div>
        );
    } else {
            return (
                <div>
                    <Redirect to={'/login'}/>
                </div>
            );
        }
    }

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        isLogin: state.checkIn.login,
        activePage : state.switcher.fullPages.component,
        activePageDefaultPasExp : state.switcher.expirationPages.component,
        isPasswordExpired: state.checkIn.expiration,
        account: state.result.currentlyModeratedAccount
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logIn: isLogin,
        addUser: responseSuccess,
        navActivePage: switchActivePage,
        switchPagePasswordExpired: switchActivePageExpPassword,
        setIsPasswordExp: isPasswordExpired,
        getAllOrigins
    },dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(Main);