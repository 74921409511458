import {IS_VIDEO_PLAY, PAGE, PAGE_PASSWORD_EXP, PAGE_VIDEOS, PLAYER} from "../actions/SwitchPageAction";
import React from "react";
import {pages, pagesPasswordExp} from "../const/Constant";
import PasswordExpired from "../components/PasswordExpired";
import FindVideo from "../components/videosPages/FindVideo";
import MainPlugin from "../components/MainPlugin";

const init = {
    fullPages : {
        component: <MainPlugin/>,
        link: 'home'
    },
    videos : {
        component: <FindVideo/>,
        link: 'video'
    },
    expirationPages : {
        component: <PasswordExpired/>,
        link: 'expired'
    },
    playing : false

};

export default function (state = init, action) {
    switch (action.type) {
        case PAGE:
            const fullPages = {
                component: pages[action.payload],
                link: action.payload
            };
            return {...state, fullPages};
        case PAGE_VIDEOS:
            const videos = {
                component: pages[action.payload],
                link: action.payload
            };
            return {...state, videos};
        case PAGE_PASSWORD_EXP:
            const expirationPages = {
                component: pagesPasswordExp[action.payload],
                link: action.payload
            };
            return {...state, expirationPages};
        case PLAYER:
            return  {...state, ref: action.payload};
        case IS_VIDEO_PLAY:
            return  {...state, playing: action.payload};
        default:
            return state
    }
}