import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiVideo} from "../../const/Constant";
import {selectedVideo, tempVideoList, videoList} from "../ResultAction";

export const changeVideoSafety = (video) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${video.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    //headers.append('X-Moderated-Account', `admin@admin`);
    if (video.email !== video.moderated) {
        headers.append('X-Moderated-Account', video.moderated);
    }
    const init = {
        method: 'PUT',
        mode: 'cors',
        headers: headers,
        body: `{"id":"${video.id}","safetyChangedBy":"${video.email}","videoSafe":"${video.videoSafe}"}`
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiVideo}/${video.email}/safe`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, video.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString().replace('TypeError:',''))))
            .then(data => {
                if (responseStatus === 200){
                    console.log(data);
                    let newList = [...video.list];
                    newList[video.index] = data;
                    dispatch(videoList(newList));
                    dispatch(tempVideoList(newList));
                    dispatch(selectedVideo(newList[video.index]));
                    dispatch(clientRequest(`video safety was changed successfully`));
                }else{
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus}), try again`));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, video.email, dispatch);
            });
    }
};