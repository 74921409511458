import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {allModerators} from "../ResultAction";

export const getVideoModerators = (email) => {
        let responseStatus = null;
        const _token = localStorage.getItem(`videocommentsxtokenuser${email}`);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('X-Token', `${_token}`);
        const init = {
            method: 'GET',
            headers: headers
        };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${email}/moderators`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    console.log(data);
                    if (data.length !== 0) {
                        dispatch(allModerators(data));
                        dispatch(clientRequest(`moderators data was received`));
                    }else{
                        dispatch(clientRequest(`no moderators found`));
                    }
                } else {
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                }
            })
            .catch(e => {
                authorizationResponseStatus(responseStatus, email, dispatch);
            });
    }
};