import React from 'react';
import {pagesNameVideos} from "../../const/Constant";
import style from "../../styles/Video.module.css";
import Video from "../Video";
import VideoInfo from "../VideoInfo";
import VideosByDatesView from "../VideosByDatesView";
import MoreActionButton from "../MoreActionButton";
import ModeratedAccount from "../ModeratedAccount";

const RemoveVideo = () => {
        return (
            <div className={'panel-container'}>
                <h2>{`${pagesNameVideos.remove}`}</h2>
                <ModeratedAccount/>
                <div className={style.container}>
                    <Video/>
                    <VideoInfo/>
                </div>
                <MoreActionButton/>
                <VideosByDatesView/>
            </div>

        );
};

export default RemoveVideo;