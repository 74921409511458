import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {editUser} from "../../actions/account/EditUserAction";
import User from "./User";

function EditUser (props){

    const submitEdit = (e) => {
        e.preventDefault();
        const name = e.target.name.value.trim();
        const phone = e.target.phone.value.trim();
        let user = {
            avatar: props.user.avatar,
            email: props.user.email,
            name: name === '' ? props.user.name : name,
            phone: phone === '' ? props.user.phone : phone,
        };
        if (user.name && user.phone) {
            props.edit(user);
        }
    };
    return (
        <div className={'d-flex align-items-center'}>
            <div className={'component'}>
                <h2>Edit Account</h2>
                <User userClass={'component'}/>
                <form onSubmit={submitEdit}>
                    <input type="text" name={'name'} placeholder={'name, 5 character min'} minLength={1}/>
                    <input type="text" name={'phone'} placeholder={'phone, 5 character min'} minLength={1}/>
                    <button className={'btn-success'}
                            type={'submit'}>Edit account
                    </button>
                </form>
            </div>
        </div>
    );
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            edit: editUser
        }, dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(EditUser);