import {clientRequest, responseError, responseSuccess} from "../StandartRequestActions";
import {urlApiAccount} from '../../const/Constant.js'
import {authorizationResponseStatus, handleResponseHeader} from "../../const/Constant";

export const editUser = (user) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${user.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
  const init = {
      method: 'PUT',
      headers: headers,
      body: `{"avatar":"${user.avatar}","name":"${user.name}","phone":"${user.phone}"}`
  };
  return (dispatch)=>{
      dispatch(clientRequest('pending...'));
      console.log(user.email)
      fetch(`${urlApiAccount}/user/${user.email}/`, init)
          .then(response => {
              responseStatus = response.status;
              return  handleResponseHeader(response, user.email);
          })
          .then(response => response.json(), e => dispatch(responseError(e)))
          .then(data => {
              console.log(data);
              if (data.email) {
                  localStorage.setItem(`videocommentsuser${data.email}`,JSON.stringify(data));
                  dispatch(clientRequest('user data was changed successfully'));
                  dispatch(responseSuccess(data));
              }else{
                  dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
              }
          })
          .catch(e => {
              console.error(e);
              authorizationResponseStatus(responseStatus, user.email, dispatch);
          });
  }
};