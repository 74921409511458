import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {moderatedAccounts} from "../ResultAction";

export const addModeratedAccountAction = (email, moderator) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    headers.append('X-Moderated-Account', moderator);

    const init = {
        method: 'PUT',
        headers: headers,
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${email}/moderated`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    console.log(data);
                    if (data.length !== 0) {
                        dispatch(moderatedAccounts(data));
                        dispatch(clientRequest(`moderated accounts data was received`));
                    }else{
                        dispatch(clientRequest(`no moderators accounts found`));
                    }
                } else {
                        dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                }
            })
            .catch(e => {
                authorizationResponseStatus(responseStatus, email, dispatch);
            });
    }
};