import React from 'react';
import {connect} from "react-redux";
import {changeUserRoles} from "../../actions/account/UserRolesAction";

function Roles(props) {

    const submitEdit = (e) => {
        e.preventDefault();
        let user = {
            email: props.user.email,
            login: e.target.login.value.trim(),
            role: e.target.role.value,
            method: e.target.action.value
        };
        if (user.login) {
            props.change(user);
        }
    };

    return (
        <div className={'d-flex align-items-center'}>
            <div className={'component'}>
                <h2>User Roles</h2>
                <form onSubmit={submitEdit}>
                    <p>For change roles</p>
                    <input type="text" name={'login'} placeholder={'type user email'}/>
                    <p>Choose user role</p>
                    <select name="role" id="role">
                        <option value="ROLE_ADMIN">Admin</option>
                        <option value="ROLE_MODERATOR">Moderator</option>
                        <option value="ROLE_USER">User</option>
                    </select>
                    <p>Choose action</p>
                    <select name="action" id="action">
                        <option value="PUT">Add role</option>
                        <option value="DELETE">Remove role</option>
                    </select>
                    <button className={'btn-success'}
                            type={'submit'}>Send
                    </button>
                </form>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        change: user => dispatch(changeUserRoles(user))
    }
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);


//export default connect(state => state, mapDispatchToProps)(Roles);