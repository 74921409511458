import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Video from "../Video";
import style from '../../styles/Video.module.css';
import VideoInfo from "../VideoInfo";
import {pagesNameVideos} from "../../const/Constant";
import {bindActionCreators} from "redux";
import {getVideoByAuthor} from "../../actions/videos/AuthorVideosAction";
import {getAllOrigins} from "../../actions/setting/AllOriginsaction";
import MoreActionButton from "../MoreActionButton";
import ModeratedAccount from "../ModeratedAccount";

const VideosByAuthor = (props) => {

    const[name,setName] = useState('');

    useEffect(() => {
        if (props.origins.length === 0) {
            props.getAllOrigins({
                email: props.user.email,
                moderatedAccount:  props.account
            });
        }
    },[]);

    const handleAuthorsVideo = (e) => {
      e.preventDefault();
        let author = {
            email: props.user.email,
            origin: e.target.originSList.value ,
            author: e.target.authorName.value,
            moderated: props.account
        };
        if (author.email && author.origin && author.author){
            setName(author.author);
            props.getVideoByAuthor(author);
        }
    };

    return (
        <div className={'panel-container'}>
            <h2>{`${pagesNameVideos.author}: ${name}`}</h2>
            <ModeratedAccount/>
            <div className={style.container}>
                <Video/>
                <VideoInfo/>
                <div>
                    <MoreActionButton/>
                </div>

            </div>

            <div>
                <form onSubmit={handleAuthorsVideo} className={style.bottomForm}>
                    <label className={'d-flex mb-0'}>
                        author :
                        <input className={'d-inline mb-0'}
                               placeholder={'type author name'}
                               style={{width: 13 + 'rem', height: 2.8 + 'rem'}}
                               type="text"
                               name="authorName"
                               min="1"
                               required/>
                    </label>
                    <label className={'d-flex mb-0'}>
                        origin :
                        <select name="originSList" style={{width: 24 + 'rem', height: 2.8 + 'rem'}} >
                            {props.origins.map((item,index) =>
                                <option value={`${item}`} key={index}>
                                    {item}
                                </option>
                            )}
                        </select>
                    </label>
                    <button className={'btn btn-info'} type={'submit'}>Get video</button>
                </form>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        origins: state.result.origins,
        user: state.userLogin.user,
        moderated: state.result.moderatedAccounts,
        account: state.result.currentlyModeratedAccount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getVideoByAuthor,
        getAllOrigins
    },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VideosByAuthor);