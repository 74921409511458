import React from "react";
import User from "../components/accountPages/User";
import ChangePassword from "../components/accountPages/ChangePassword";
import DeleteUser from "../components/accountPages/DeleteUser";
import EditUser from "../components/accountPages/EditUser";
import Roles from "../components/accountPages/Roles";
import PasswordExpired from "../components/PasswordExpired";
import FindVideo from "../components/videosPages/FindVideo";
import VideosByDateCreation from "../components/videosPages/VideosByDateCreation";
import ChangeVideoSafe from "../components/videosPages/ChangeVideoSafe";
import AuthorVideos from "../components/videosPages/AuthorVideos";
import AllOrigins from "../components/settingsPages/AllOrigins";
import OriginVideos from "../components/videosPages/OriginVideos";
import VideosForModeration from "../components/videosPages/VideosForModeration";
import RemoveVideo from "../components/videosPages/RemoveVideo";
import ReportedVideos from "../components/videosPages/ReportedVideos";
import {isLogin} from "../actions/IsLoginAction";
import {clientRequest} from "../actions/StandartRequestActions";
import SafetyAdjustments from "../components/settingsPages/SafetyAdjustments";
import Moderators from "../components/settingsPages/Moderators";
import MainPlugin from "../components/MainPlugin";


export const urlApiAccount = 'https://videocommentsrouter.onrender.com/account/en/v1';
export const urlApiVideo = 'https://videocommentsrouter.onrender.com/videos/en/v1/admin';
export const urlApiAdminPanel = 'https://videocommentsrouter.onrender.com/admin/en/v1/email';

export const pages = {
    home : <MainPlugin/>,
    user : <User userClass={'component'}/>,
    password: <ChangePassword/>,
    delete: <DeleteUser/>,
    edit: <EditUser/>,
    roles: <Roles/>,
    video: <FindVideo/>,
    date: <VideosByDateCreation/>,
    safe: <ChangeVideoSafe/>,
    author: <AuthorVideos/>,
    origins: <AllOrigins/>,
    originVideos: <OriginVideos/>,
    moderation: <VideosForModeration/>,
    remove: <RemoveVideo/>,
    report: <ReportedVideos/>,
    safetyAdjustments: <SafetyAdjustments/>,
    moderators: <Moderators/>
};

export const pagesName = {
    user : "User info",
    password: 'Change password',
    delete: 'Delete account',
    edit: 'Edit user info',
    roles: 'User roles',
};

export const pagesNameSettings = {
    safetyAdjustments : "AI safety adjustments",
    origins: 'Origins',
    moderators: 'Moderators'
};


export const pagesPasswordExp = {
    expired: <PasswordExpired/>,
};

export const pagesNameVideos = {
    video: 'Find video',
    safe: 'Change video safe',
    remove: 'Remove video',
    author: "Video by author",
    date: 'Video by date',
    originVideos: 'Safety video by origin',
    moderation: 'Video for moderation',
    report: 'Reported video',
};

export const removeMessage = {
    title: 'Perform delete operation',
    text: 'Are you sure you want to delete this video? This operation cannot be undone.',
    actionButton: 'Delete',
    buttonType: 'danger'
};

export const changeSafetyMessage = {
    title: 'Perform change safety operation',
    text: 'Are you sure you want to change this video to safety?',
    actionButton: 'Change to safety',
    buttonType: 'success'
};

export const changeNonSafetyMessage = {
    title: 'Perform change safety operation',
    text: 'Are you sure you want to change this video to non-safety?',
    actionButton: 'Change to non-safety',
    buttonType: 'danger'
};

export const saveSafetyAdjustmentsMessage = {
    title: 'Perform change safety adjustments operation',
    text: 'Are you sure you want to save this video safety adjustments?',
    actionButton: 'Save adjustments',
    buttonType: 'info'
};

export const removeModeratorMessage = {
    title: 'Perform remove moderator operation',
    text: 'Are you sure you want to remove this moderator?',
    actionButton: 'Remove',
    buttonType: 'danger'
};

export const addModeratorMessage = {
    title: 'Perform add moderator operation',
    text: 'Are you sure you want to add this moderator?',
    actionButton: 'Add',
    buttonType: 'success'
};

export const safetyAdjustmentsView = {
    minnudbound: {title: `nudity safe`, min: 60, max: 90, defaultRange: 75, nik: "minnudbound"},
    maxwpnbound: {title: "weapon quantity", min: 20, max: 50, defaultRange: 35, nik: "maxwpnbound"},
    maxoffnsvbound: {title: "offensive", min: 20, max: 50, defaultRange: 35, nik: "maxoffnsvbound"},
    maxdrgbound: {title: "drugs quantity", min: 20, max: 50, defaultRange: 35, nik: "maxdrgbound"},
    maxalcbound: {title: "alcohol quantity", min: 20, max: 50, defaultRange: 35, nik: "maxalcbound"}
};

export const frameIdText = (frameId) => (
  `<script>
    window.onload = function() {
        document.getElementById("${frameId}")
            .contentWindow
            .postMessage({"origin": window.location.href,"frameId": "${frameId}"},
                "https://master.d24i7fxc5n6xe.amplifyapp.com/");
    };
</script>
<div  style="display: flex;justify-content: center;">
    <iframe id="${frameId}"
            width="600" height="733"
            src="https://master.d24i7fxc5n6xe.amplifyapp.com/"
            allow="camera *;microphone *"
            allowFullScreen
            style = "border: 2px solid grey;border-radius: 8px;
            visibility: visible; box-shadow: 0 0 20px 0 black;
            margin-bottom: 70px;">
    </iframe>
</div>`
);

export  const  findLocalItems = (query) => {
    let i, value, results = [];
    for (i in localStorage) {
        if (localStorage.hasOwnProperty(i)) {
            if (i.match(query)) {
                value = JSON.parse(localStorage.getItem(i));
                results.push({key:i,val:value});
            }
        }
    }
    return results;
};

export const authorizationResponseStatus = (responseStatus, email, dispatch) => {
    if (responseStatus === 401) {
        dispatch(isLogin(false));
        dispatch(clientRequest(`authorization required`));
        localStorage.removeItem(`videocommentsexpireduser${email}`);
        localStorage.removeItem(`videocommentsxtokenuser${email}`);
        localStorage.removeItem(`videocommentsuser${email}`);
    }else{
        dispatch(clientRequest(`Something went wrong, try again later`));
    }
};

export const handleResponseHeader = (response, email) => {
    if(response.headers.get('X-Token')) {
        localStorage.setItem(`videocommentsxtokenuser${email}`, response.headers.get('X-Token'));
    }
    return response;
};


