import React from 'react';
import {loginUser} from "../actions/account/LoginUserAction";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, Redirect} from "react-router-dom";
import Footer from "./Footer";
import {switchActivePage, switchActivePageExpPassword} from "../actions/SwitchPageAction";
import Logo from "./Logo";

function Login(props) {
    const submitLogin = (e) => {
        e.preventDefault();
        let login = {
            login: e.target.login.value.trim(),
            password: e.target.password.value.trim(),
        };
        props.login(login);
    };

    if (!props.isLogin) {
        return (
            <>
                <div className={'login-container'}>
                    <div className={'login-background-color'}>
                        <Logo size={'login-size display-1-shadow'}/>
                        <h2 className={'login-title'}>Login</h2>
                        <form onSubmit={submitLogin}>
                            <input type="text" name={'login'} placeholder={'email'}/>
                            <input type="password" name={'password'} placeholder={'password'}/>
                            <button className={'btn-info mb-5'} type={'submit'}>Log in</button>
                        </form>
                    </div>
                    <h4 className={'mt-5 mb-3'}>Have no account? Register now</h4>
                    <Link to={'/register'} className={'text-decoration-none'}><p
                        className={'btn-success btn-login-register'}>Register</p></Link>
                </div>
                <Footer/>
            </>
        );
    } else {
        props.switchPage('home');
        props.switchPagePasswordExpired('expired');
        return (
            <div>
                <Redirect to={`${props.isPasswordExpired ? '/expired' : '/main/home'}`}/>
                <Footer/>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        login: loginUser,
        switchPage: switchActivePage,
        switchPagePasswordExpired: switchActivePageExpPassword
        }, dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        isLogin: state.checkIn.login,
        isPasswordExpired: state.checkIn.expiration
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);