import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import styleCheckbox from "../styles/Chekbox.module.css";
import {Button} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {getAllOrigins} from "../actions/setting/AllOriginsaction";
import {getOriginsWithRecordedVideos} from "../actions/setting/OriginsWithRecordedVideosAction";
import {getNotConfirmedOrigins} from "../actions/setting/NotConfirmedOriginsAction";
import {confirmOrigins} from "../actions/setting/ConfirmOriginsAction";

const TrustOrigin = (props) => {

    const[notConfirmed,setNotConfirmed] = useState([]);
    const[disableButtonConfirm,setDisableButtonConfirm] = useState(true);

    const handleConfirmOrigin = (e) => {
        e.preventDefault();
        if (e.target.name === 'checkingBoxes') {
            let arr = getChecked(e);
            arr.length > 0 && props.confirmOrigins({email: props.user.email, origins:arr});
        }
    };

    const handleChange = (e) => {
        getChecked(e).length > 0 ? setDisableButtonConfirm(false) : setDisableButtonConfirm(true);
    };

    function getChecked(e){
        let arr = [];
        let checked = [...e.currentTarget.querySelectorAll(`[name=box]:checked`)];
        for (const obj of checked){
            arr.push(obj.defaultValue)
        }
        return arr;
    }

    function clearChecked(){
        let checked = [...document.querySelectorAll(`[name=box]:checked`)];
        for (const obj of checked){
            obj.checked = false;
        }

    }

    useEffect(() => {
            props.getNotConfirmedOrigins(props.user.email);
    }, []);

    useEffect(() => {
        if (props.notConfirmedOrigins.length >= 0){
            clearChecked();
            setDisableButtonConfirm(true);
            setNotConfirmed([...props.notConfirmedOrigins]);
        }
    }, [props.notConfirmedOrigins]);

    return (
        <div>
            <h3>{`You have not confirmed origins`}</h3>
            <form name={'checkingBoxes'} onSubmit={handleConfirmOrigin} onChange={handleChange}>
                <div className={'board-shadow'}>
                    <div className={`board board-message`}
                        // style={{width: 60 + 'rem', height: 10 + 'rem', textAlign: 'left'}}
                    >
                        <ol>
                            {notConfirmed.map((item, index) =>
                                (<li key={index}>
                                    {<div>
                                        <label className={styleCheckbox.container}>{item}
                                            <input type="checkbox" name={'box'} value={item}/>
                                            <span className={styleCheckbox.checkMark}/>
                                        </label>
                                    </div>}
                                </li>))}
                        </ol>
                    </div>
                </div>
                <Button variant={'info'} type="submit" disabled={disableButtonConfirm}>Confirm</Button>
            </form>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllOrigins,
        getOriginsWithRecordedVideos,
        getNotConfirmedOrigins,
        confirmOrigins
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        origins: state.result.origins,
        notConfirmedOrigins: state.result.notConfirmedOrigins,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrustOrigin);