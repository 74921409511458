import {clientRequest, responseError} from "../StandartRequestActions";
import {urlApiAccount} from '../../const/Constant.js'
import {isLogin, isPasswordExpired} from "../IsLoginAction";
import {switchActivePage} from "../SwitchPageAction";
import {authorizationResponseStatus, handleResponseHeader} from "../../const/Constant";

export const changePassword = (user) => {
    let responseStatus = null;
    const _btoaToken = `Basic ${btoa(`${user.email}:${user.oldPassword}`)}`;
    const _token = localStorage.getItem(`videocommentsxtokenuser${user.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${_btoaToken}`);
    headers.append('X-Password', `${user.newPassword}`);
    headers.append('X-Token', `${_token}`);
    const init = {
        method: 'PUT',
        headers: headers
    };
    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAccount}/user/${user.email}/password`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, user.email);
            })
            .then(response => response.status, e => dispatch(responseError(e)))
            .then(status => {
                if (status === 200) {
                    localStorage.setItem(`videocommentsexpireduser${user.email}`, '0');
                    dispatch(isPasswordExpired(false));
                    dispatch(clientRequest('Password was change successfully'));
                    dispatch(isLogin(true));
                    dispatch(switchActivePage('home'));
                }else if (status === 401){
                        dispatch(clientRequest("Wrong password"));
                    }else {
                        dispatch(clientRequest("Wrong data"));
                    }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, user.email, dispatch);
            });
    }
};