import React, {useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Button from "react-bootstrap/Button";
import {removeMessage} from "../const/Constant";
import MessageWindow from "./MessageWindow";
import {removeVideo} from "../actions/videos/RemoveVideoAction";

const DeleteVideoButton = (props) => {

    const [modalShow, setModalShow] = useState(false);

    const handleRemove = () => {
        let video = {
            email: props.user.email,
            id: props.selectedVideo.id,
            list: props.list,
            index: props.list.indexOf(props.selectedVideo),
            moderated: props.account
        };
        if (video.email && video.id){
            props.removeVideo(video);
        }
    };

    const handleCloseModalWindow = () => {
        setModalShow(false);
    };

    const handleRemoveClick = () => {
        setModalShow(true);
    };

    return (
        <div>
            <Button className={'btn-danger'} onClick={handleRemoveClick}>
                Remove video
            </Button>

            <MessageWindow show={modalShow}
                           onHide={handleCloseModalWindow}
                           message={removeMessage}
                           perform={handleRemove}
            />
        </div>

    );
};

function mapStateToProps(state) {
    return {
        list: state.result.tempList,
        selectedVideo: state.result.video,
        user: state.userLogin.user,
        account: state.result.currentlyModeratedAccount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        removeVideo
    },dispatch);

}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteVideoButton);