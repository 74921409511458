import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiVideo} from "../../const/Constant";
import {selectedVideo, tempVideoList, videoList} from "../ResultAction";

export const getVideoBetweenDate = (dates) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${dates.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    if (dates.email !== dates.moderated) {
        headers.append('X-Moderated-Account', dates.moderated);
    }
    const init = {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: `{"dateFrom":"${dates.from}T00:00:00","dateTo":"${dates.to}T00:00:00"}`
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiVideo}/${dates.email}/date`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, dates.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString().replace('TypeError:',''))))
            .then(data => {
                if (responseStatus === 200){
                   dispatch(videoList(data));
                    dispatch(tempVideoList(data));
                    dispatch(selectedVideo(data.length > 0 ? data[0] : ''));
                    dispatch(clientRequest(`${data.length} video${data.length > 1 ? 's' : ''} received`));
                }else{
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus}), try again`));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, dates.email, dispatch);
            });
    }
};