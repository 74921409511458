import React from 'react';
import {connect} from "react-redux";
import {clientRequest, responseSuccess} from "../actions/StandartRequestActions";
import {bindActionCreators} from "redux";
import {changePassword} from "../actions/account/ChangePasswordAction";
import ChangePassword from "./accountPages/ChangePassword";
import Navigation from "./Navigation";
import {isPasswordExpired} from "../actions/IsLoginAction";
import {findLocalItems} from "../const/Constant"
import {Redirect} from "react-router-dom";
import Footer from "./Footer";
import {pagesPasswordExp} from "../const/Constant";

function PasswordExpired(props) {

    /*if (props.user === undefined){
        let user = findLocalItems(/videocommentsuser(.*)/);
        if (localStorage.getItem(`videocommentsexpireduser${user.email}`) !== '1'){
            return (
                <div>
                    <Redirect to={`/main/home`}/>
                </div>
            )
        }
        if (user.length !== 1){
            return (
                <div>
                    <Redirect to={'/login'}/>
                </div>
            );
        }else {
            props.setUser(user[0].val);
        }
    }*/

    if (props.isPasswordExp) {
        return (
            <div>
                <Navigation/>
                <h2>Password Expired</h2>
                <ChangePassword/>
                <Footer/>
            </div>
        );
    } else {
        return (
            <div>
                <Redirect to={`/main/home`}/>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendInfo: clientRequest,
        setUser: responseSuccess,
        isPasswordExpired,
        changePassword
    },dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.userLogin.user,
        isPasswordExp: state.checkIn.expiration,
        activePage : state.switcher.fullPages.component
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(PasswordExpired);