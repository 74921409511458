import React from 'react';
import Video from "../Video";
import style from '../../styles/Video.module.css';
import VideoInfo from "../VideoInfo";
import VideosByDatesView from "../VideosByDatesView";
import {pagesNameVideos} from "../../const/Constant";
import MoreActionButton from "../MoreActionButton";
import ModeratedAccount from "../ModeratedAccount";

const VideosByDateCreation = () => {
    return (
            <div className={'panel-container'}>
                <h2>{pagesNameVideos.date}</h2>
                <ModeratedAccount/>
                <div className={style.container}>
                    <Video/>
                    <VideoInfo/>
                </div>
                <MoreActionButton/>
                <VideosByDatesView/>
            </div>
        );
    };

export default VideosByDateCreation;