import React from 'react';
import {registerNewUser} from "../actions/account/RegisterNewUserAction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Redirect} from "react-router-dom";
import Footer from "./Footer";
import Logo from "./Logo";

function Register(props) {
    const submitRegister = (e) => {
        e.preventDefault();
        let user = {
            name: e.target.name.value.trim(),
            email: e.target.email.value.trim(),
            password: e.target.password.value.trim()
        };
        if (user.name && user.email && user.password) {
            props.register(user);
        }
    };

    if (!props.isLogin) {
        return (
            <>
                <div className={'login-container'}>
                    <div className={'login-background-color'}>
                        {/*<h1 className={'fixed-top display-1'}>Video Talk Chat</h1>*/}
                        <Logo size={'login-size display-1-shadow'}/>
                        <h2 className={'login-title'}>Register</h2>
                        <form onSubmit={submitRegister}>
                            <input type="text" name={'name'} placeholder={'name, 5 character min'} minLength={5}/>
                            <input type="text" name={'email'} placeholder={'email, 6 character min'} minLength={6}/>
                            <input type="password" name={'password'} placeholder={'password, 10 character min'}
                                   minLength={10}/>
                            <button className={'btn-success mb-5'} type={'submit'}>Register</button>
                        </form>
                    </div>
                    <h4 className={'mt-5 mb-3'}>Already registered? Login now</h4>
                    <Link to={'/login'} className={'text-decoration-none'}><p
                        className={'btn-info btn-login-register'}>Login</p></Link>
                    </div>
                    <Footer/>

            </>
        );
    } else {
        return (
            <div>
                <Redirect to={'/main/home'}/>
                <Footer/>
            </div>
        )
    }
}

    function mapDispatchToProps(dispatch) {
        return{
            register: user => dispatch(registerNewUser(user))
        }
    }

    function mapStateToProps(state) {
        return {
            isLogin: state.checkIn.login
        }
    }

export default connect(mapStateToProps,mapDispatchToProps)(Register);