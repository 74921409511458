import userLogin from './UserReducer';
import checkIn from './CheckInReducer';
import switcher from './SwitchPageReducer';
import result from './ResultReducer';
import {combineReducers} from "redux";

export const rootReducer = combineReducers({
   userLogin,
   checkIn,
   switcher,
   result
});