import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import style from "../styles/Video.module.css";
import {selectedVideo, tempVideoList} from "../actions/ResultAction";
import {connect} from "react-redux";
import {isVideoPlay} from "../actions/SwitchPageAction";


const VideosList = (props) => {

        useEffect(() => {
            // Specify how to clean up after this effect(ComponentWillUnmount):
            return () => {
                props.tempVideoList([]);
                props.selectedVideo('');
            };
        },[]);

    useEffect(() => {
            props.tempVideoList([]);
            props.selectedVideo('');
    },[props.account]);

    const submitClickPlay = (e) => {
        e.preventDefault();
        if (props.playerReference.current) {
            props.playerReference.current.player.seekTo(0);
            props.isVideoPlay(!props.isPlaying);
        }
    };

    const handleSelect = (e) => {
        if (e.target) {
            props.selectedVideo(props.list[e.target.value]);
            props.isVideoPlay(false);
        }
    };

    return (
        <div>
            {props.list.length !== 0 ?
                <form className={'d-flex'} onSubmit={submitClickPlay}>
                    <select className={style.fieldList} name="videosList" id="videosList"  onChange={handleSelect}>
                        {props.list.map((item,index) =>
                            <option className={item.videoSafe ? style.black : style.grey}
                                    value={`${index}`}
                                    key={index}
                                   >
                                {item.videoUrl ? `${index + 1}. ${item.author} ${item.videoSafe ? 'safety' :
                                    'non-safety'} ${item.dateCreated.split('T')[0]}`
                                : item.id ? `${index + 1}. video removed, id: ${item.id}` : ''}
                            </option>
                        )}
                    </select>
                    <button className={`btn btn-outline-${props.isPlaying ? 'danger' : 'success'} custom-${props.isPlaying ? 'danger' : 'success'}  mr-3`}
                            type={'submit'}>{props.isPlaying ? 'Stop' : 'Play'}
                    </button>
                </form>
             : ''}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectedVideo,
        tempVideoList,
        isVideoPlay
    },dispatch);
}

function mapStateToProps(state) {
    return {
        list: state.result.tempList,
        selectedVideo: state.result.video,
        currentPage: state.switcher.fullPages.link,
        playerReference: state.switcher.ref,
        isPlaying: state.switcher.playing,
        account: state.result.currentlyModeratedAccount
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(VideosList);