import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel, urlApiVideo} from "../../const/Constant";
import {allOrigins} from "../ResultAction";
import {updateAdminDataBase} from "./UpdateAdminDataBaseAction";

export const getOriginsWithRecordedVideos = (accountData) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${accountData.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);

    if (accountData.email !== accountData.moderatedAccount && accountData.moderatedAccount !== '') {
        headers.append('X-Moderated-Account', accountData.moderatedAccount);
    }

    const init = {
        method: 'GET',
        headers: headers
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiVideo}/${accountData.email}/origins`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, accountData.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    if ( data.length > 0) {
                        console.log(data);
                        dispatch(allOrigins(data));
                        dispatch(clientRequest(`total: ${data.length} ${data.length === 1 ? 'origin' : 'origins'} with recorded videos`));
                        //dispatch(updateAdminDataBase(accountData.email, data));
                    } else {
                        dispatch(clientRequest(`no origins found`));
                    }
                } else {
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                }
            })
            .catch(e => {
                console.error(e);
                authorizationResponseStatus(responseStatus, accountData.email, dispatch);
            });
    }
};