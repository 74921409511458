import {clientRequest, responseError} from "../StandartRequestActions";
import {authorizationResponseStatus, handleResponseHeader, urlApiAdminPanel} from "../../const/Constant";
import {safetyAdjustmentsFromAdmin} from "../ResultAction";

export const updateSafetyAdjustments = (adjustments) => {
    let responseStatus = null;
    const _token = localStorage.getItem(`videocommentsxtokenuser${adjustments.email}`);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Token', `${_token}`);
    const init = {
        method: 'PUT',
        headers: headers,
        body: `{"email": "${adjustments.email}", "videoSafeAdjustments":${JSON.stringify(adjustments.data)}}`
    };

    return (dispatch) => {
        dispatch(clientRequest('pending...'));
        fetch(`${urlApiAdminPanel}/${adjustments.email}/safe`, init)
            .then(response => {
                responseStatus = response.status;
                return  handleResponseHeader(response, adjustments.email);
            })
            .then(response => response.json(), e => dispatch(responseError(e.toString())))
            .then(data => {
                if (responseStatus === 200) {
                    console.log(data);
                    if (data.videoSafeAdjustments.length !== 0) {
                        let rangeArray = {...data.videoSafeAdjustments};
                        for (const property in rangeArray) {
                            rangeArray[property] = Math.round(rangeArray[property] * 100);
                        }
                        dispatch(safetyAdjustmentsFromAdmin(rangeArray));
                        dispatch(clientRequest(`safety adjustments data was saved successfully`));
                    }else{
                        dispatch(clientRequest(`safety adjustments data not saved, try again`));
                    }
                } else {
                    dispatch(clientRequest(`Something went wrong(error: ${responseStatus})`));
                }
            })
            .catch(e => {
                authorizationResponseStatus(responseStatus, adjustments.email, dispatch);
            });
    }
};